.header {
    padding: 30px;
    text-align: center;
    background: #E4B7A0;
    color: white;
    font-size: 30px;
}

.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #F6EEE0;
    color: grey;
    text-align: center;
}

.warning {
    width: 100%;
    background: #cfc;
    border-radius: 3px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 2;
    text-align: center;
    margin-bottom: 10px;
}

.gen-form {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    font-size: 25px;
    gap: 10px;
}

.bold {
    font-weight: bold;
}

.blur {
    color: transparent;
    text-shadow: 0 0 8px #000;
}


.prefix {
    height: 45px;
    font-weight: bold;
    font-size: 25px;
    padding-right: 10px;
}

.OX {
    font-weight: bold;
    height: 45px;
    line-height: 45px;
}

.generate-container {
    height: 45px;
    width: 380px;

    margin: 0 auto;
    display: flex;
    flex-direction: row
}

.generate-button {
    display: block;
    margin: 0 auto;
    height: 45px;
    width: 380px;
}

.copy-show {
    margin-left: 10px;
    display: inline-block;
    outline: 0;
    border: 0;
    cursor: pointer;
    background: #000000;
    color: #FFFFFF;

    font-size: 18px;
    font-weight: 700;
    line-height: 1;
    transition: transform 200ms, background 200ms;
}

.copy-show:hover {
    transform: translateY(-2px);
}

.info {
    font: .6em tahoma, sans-serif;
    display: flex;
    flex-direction: row;
}

.info-box {
    width: 200px;
    margin-right: 20px;
}

/*css resets*/
* {
    box-sizing: border-box;
}

body {
    font-family: "Karla", sans-serif;
    margin: 0;
}

